import { Controller } from "@hotwired/stimulus"
import OpenSeadragon from "openseadragon"

export default class extends Controller {
  static values = {
    tileSources: Object
  }
  static classes = ["dragging"]

  async connect() {
    this.element[this.identifier] = this
    this.viewer = OpenSeadragon({
      element: this.element,
      showNavigationControl: false,
      tileSources: this.tileSourcesValue,
      minZoomLevel: 1.0,
      maxZoomLevel: 4.0,
      defaultZoomLevel: 1.0,
      constrainDuringPan: true,
      visibilityRatio: 1.0,
      springStiffness: 4.0,
      animationTime: 0.2
    })
    this.viewer.addHandler("canvas-drag", () => {
      this.element.classList.add(this.draggingClass)
    })
    this.viewer.addHandler("canvas-drag-end", () => {
      this.element.classList.remove(this.draggingClass)
    })
    this.viewer.addHandler("zoom", event => {
      let { zoom } = event
      zoom = this.ensureFloat(zoom)
      this.dispatch("zoom", {
        detail: {
          level: zoom,
          isMaxZoom: zoom == this.maxZoom,
          isMinZoom: zoom == this.minZoom
        }
      })
    })
  }

  disconnect() {
    if (this.viewer) this.viewer.destroy()
  }

  zoomIn() {
    this.viewport.zoomBy(2.0 / 1.0)
    this.viewport.applyConstraints()
  }

  zoomOut() {
    this.viewport.zoomBy(1.0 / 2.0)
    this.viewport.applyConstraints()
  }

  reset() {
    this.viewport.goHome()
  }

  ensureFloat(n) {
    if (this.isInt(n)) {
      return n.toFixed(1)
    } else if (this.isFloat(n)) {
      return n
    }
  }

  isInt(n) {
    return Number(n) === n && n % 1 === 0
  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0
  }

  get maxZoom() {
    return this.viewport.getMaxZoom()
  }

  get minZoom() {
    return this.viewport.getMinZoom()
  }

  get viewport() {
    return this.viewer.viewport
  }
}
